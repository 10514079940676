import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Easy D3 blackbox components with React hooks",
  "description": "Combining D3 and React can be tricky. D3 loves internal state, React likes components to be stateless.",
  "date": "2018-11-01T08:00:00.000Z",
  "published": "2018-11-01T08:00:00.000Z",
  "image": "../../../defaultHero.jpg"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h3 {...{
      "id": "what-if-you-could-take-any-random-d3-example-and-wrap-it-in-a-react-component-in-just-2-minutes-wouldnt-that-be-great-"
    }}><em parentName="h3">{`What if you could take any random D3 example and wrap it in a React component in just 2 minutes? Wouldn`}{`'`}{`t that be great?`}</em>{` 👌`}</h3>
    <p>{`Combining D3 and React can be tricky. D3 loves internal state, React likes components to be stateless. I wrote `}<a parentName="p" {...{
        "href": "https://gum.co/pMtnZ"
      }}>{`a whole book about it`}</a>{` there`}{`'`}{`s so much nuance.`}</p>
    <p><em parentName="p">{`"`}{`Fully controlled components`}{`"`}</em>{` is the latest buzzword I believe. Rendering depends on props.`}</p>
    <p>{`D3 likes to set up objects with internal state. You call them and out comes a beautiful picture. A data visualization. A piece of art. Anything really it`}{`'`}{`s very flexible.`}</p>
    <p>{`Something like this for example 👇`}</p>
    <p><a parentName="p" {...{
        "href": "http://blockbuilder.org/kafunk/91f7b870b79c2f104f1ebacf4197c9dc"
      }}><img parentName="a" {...{
          "src": "/43bbaa89f4f70a9346447eba8fe763cb/blog-wp-content-uploads-2018-11-animated-line.gif",
          "alt": null
        }}></img></a></p>
    <p>{`No, I don`}{`'`}{`t know how that example works. And that`}{`'`}{`s the point.`}</p>
    <p><em parentName="p">{`Most`}</em>{` people write D3 by looking for examples, copy-pasting some code, and tweaking until it looks right.`}</p>
    <p>{`But most D3 examples out there are written as one-off pieces of art. With flat JavaScript, old conventions, and code that`}{`'`}{`s not very reusable.`}</p>
    <p>{`React doesn`}{`'`}{`t like that.`}</p>
    <h2 {...{
      "id": "d3-blackbox-components"
    }}>{`D3 blackbox components`}</h2>
    <p>{`Enter blackbox components: A quick way to wrap any D3 example in a React component.`}</p>
    <p>{`Here`}{`'`}{`s an old video of mine explaining how that works 👇`}</p>
    <lite-youtube {...{
      "videoid": "pzMTVChKvjo",
      "videostartat": "0"
    }}></lite-youtube>
    <p>{`The approach works like this:`}</p>
    <ol>
      <li parentName="ol">{`Find example you like`}</li>
      <li parentName="ol">{`Copy the code`}</li>
      <li parentName="ol">{`Wrap it in a render function`}</li>
      <li parentName="ol">{`Use a `}<inlineCode parentName="li">{`D3blackbox`}</inlineCode>{` higher order component to render an anchor element`}</li>
      <li parentName="ol">{`Use mount and update hooks to render D3 into the anchor`}</li>
      <li parentName="ol">{`React controls the anchor element, D3 controls the insides`}</li>
    </ol>
    <p>{`This is a quick way to integrate any D3 example in your React code. Fast to set up, easy to work with.`}</p>
    <p>{`Here`}{`'`}{`s a CodeSandbox from my workshops using the blackbox approach to make a random barchart reusable.`}</p>
    <p>{`👌`}</p>
    <p>{`The HOC to make that work is a little scary. Hard to explain too.`}</p>
    <pre><code parentName="pre" {...{}}>{`export default function D3blackbox(D3render) {
  return class Blackbox extends React.Component {
    anchor = React.createRef();

    componentDidMount() {
      D3render(this.anchor, this.props, this.state);
    }
    componentDidUpdate() {
      D3render(this.anchor, this.props, this.state);
    }

    render() {
      const { x, y } = this.props;
      return ;
    }
  };
}
`}</code></pre>
    <p>{`17 sloc function that returns a class-based component with an `}<inlineCode parentName="p">{`anchor`}</inlineCode>{`, two lifecycle methods, and a rendered \`\` element. That`}{`'`}{`s our anchor.`}</p>
    <p>{`Using this HOC looks like wrapping some D3 code in a `}<inlineCode parentName="p">{`D3blackbox`}</inlineCode>{` call.`}</p>
    <pre><code parentName="pre" {...{}}>{`const Barchart = D3blackbox(function(anchor, props) {
  var svg = d3.select(anchor.current),
  // ...
  
`}</code></pre>
    <p>{`Your D3 code gets the anchor, props, and state. Do what you want.`}</p>
    <h2 {...{
      "id": "hooks-make-blackboxes-easier"
    }}>{`Hooks make blackboxes easier`}</h2>
    <p>{`That`}{`'`}{`s all fine and good, but we can make it even better with React`}{`'`}{`s new hooks proposal. Hooks are an early RFC, and as of November 2018, this API is still likely to change.`}</p>
    <p>{`But you can try them out with React 16.7 alpha. Just set your `}<inlineCode parentName="p">{`package.json`}</inlineCode>{` to `}<inlineCode parentName="p">{`next`}</inlineCode>{`.`}</p>
    <p>{`Using the same idea:`}</p>
    <ol>
      <li parentName="ol">{`React controls anchor node`}</li>
      <li parentName="ol">{`D3 controls the insides`}</li>
      <li parentName="ol">{`Render D3 on all updates`}</li>
      <li parentName="ol">{`Wrap D3 in React super fast`}</li>
    </ol>
    <p>{`Hooks let us achieve all that in just 8 lines of code. Versus the 17 it took with a HOC.`}</p>
    <p>{`Here`}{`'`}{`s me figuring it out in a ninja livecode session 👇`}</p>
    <lite-youtube {...{
      "videoid": "MatW9u7ley0",
      "videostartat": "0"
    }}></lite-youtube>
    <p>{`And here`}{`'`}{`s proof that it takes just 2 minutes to take any random D3 example and wrap it in a React component. Whole video fits in a tweet 👇`}</p>
    <p>{`https`}{`&`}{`#x3A;//twitter.com/Swizec/status/1057519327774232576`}</p>
    <p>{`Here`}{`'`}{`s how it works:`}</p>
    <pre><code parentName="pre" {...{}}>{`const D3blackbox = ({ x, y, render }) => {
  const refAnchor = React.useRef(null);

  React.useEffect(() => {
    render(d3.select(refAnchor.current));
  });

  return ;
};
`}</code></pre>
    <p>{`We have a `}<inlineCode parentName="p">{`D3blackbox`}</inlineCode>{` component that takes coordinates and a render method as props. It uses the `}<inlineCode parentName="p">{`useRef`}</inlineCode>{` hook to add a React ref to our functional component and `}<inlineCode parentName="p">{`useEffect`}</inlineCode>{` to automatically call your `}<inlineCode parentName="p">{`render`}</inlineCode>{` method on component mount and update.`}</p>
    <p>{`It passes a `}<inlineCode parentName="p">{`d3.select`}</inlineCode>{`-ed element into your render method so you can get started with D3 stuff right away.`}</p>
    <p>{`Then it renders the anchor element as an SVG group.`}</p>
    <p>{`You use it like this:`}</p>
    <pre><code parentName="pre" {...{}}>{` // D3 code} />
`}</code></pre>
    <p>{`😍`}</p>
    <h2 {...{
      "id": "caveats"
    }}>{`Caveats`}</h2>
    <p>{`Hooks themselves aren`}{`'`}{`t really ready for realzies quite yet. You can play around, but I don`}{`'`}{`t recommend using in production just yet or rewriting your apps.`}</p>
    <p>{`React ecosystem sure seems excited though. That genie is never going back in its bottle.`}</p>
    <p>{`D3 blackbox rendering itself has a few caveats. You`}{`'`}{`re destroying and reconstructing your entire D3 visualization on every update. This can become costly.`}</p>
    <p>{`Once you take control away from React, its beautiful algorithms and optimizations can`}{`'`}{`t help you.`}</p>
    <p>{`Quick prototypes and experiments, though, this is perfect 👍🏼`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      